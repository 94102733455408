import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src928099888/src/ConSite/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
import { CollapsibleStyled, CollapsibleHeader, CollapsibleChild } from '../styles/Collapsible';
import GenericCard from '../lib/GenericCard';
import StaticSiteJPG from '../assets/static_site.png';
import GreenfieldJPG from '../assets/windows_greenfield.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "summary"
    }}>{`Summary`}</h2>
    <div>
  Hey, I'm Hakim Ahmad and I make websites for a living since {new Date().getFullYear() - 2015} years ago. I worked on variety of projects including startups, e-commerce, greenfield project, legacy project, system design, system migration, and system maintenance.
    </div>
    <br />
    <h2 {...{
      "id": "strengths"
    }}>{`Strengths`}</h2>
    <p>
  <CollapsibleStyled title="first" mdxType="CollapsibleStyled">
    <CollapsibleHeader mdxType="CollapsibleHeader">
      Familiar Tech Stack
    </CollapsibleHeader>
    <CollapsibleChild mdxType="CollapsibleChild">
      <div>Ruby on Rails</div>
      <div>Solidus (RoR's Ecommerce Framework)</div>
      <div>General Webstack: PostgreSQL, HTML, Javascript, Ruby, PHP, MySQL</div>
      <div>Developer Tools: Git, Redis, Postman, Rollbar, New Relic</div>
      <div>DevOps: Google Cloud Platform, Amazon Web Services, Heroku, Cloudflare, Namecheap, Kubernetes</div>
      <div>Mobile App: Ignite (React Native's Framework)</div>
      <div>Hobby: Clojure, Re-frame (clojure web stack)</div>
      <br />
    </CollapsibleChild>
  </CollapsibleStyled>
  <CollapsibleStyled title="second" mdxType="CollapsibleStyled">
    <CollapsibleHeader mdxType="CollapsibleHeader">
      Technical's Skill
    </CollapsibleHeader>
    <CollapsibleChild mdxType="CollapsibleChild">
      <div>Software Architectural Design</div>
      <div>Database Design</div>
      <div>Feature Design</div>
      <div>Debugging</div>
      <br />
    </CollapsibleChild>
  </CollapsibleStyled>
  <CollapsibleStyled title="third" mdxType="CollapsibleStyled">
    <CollapsibleHeader mdxType="CollapsibleHeader">
      People's Skill
    </CollapsibleHeader>
    <CollapsibleChild mdxType="CollapsibleChild">
      <div>Remote working</div>
      <div>Write and speak English fluently</div>
      <div>Team's brainstorming</div>
      <br />
    </CollapsibleChild>
  </CollapsibleStyled>
    </p>
    <br />
    <h2 {...{
      "id": "works-on"
    }}>{`Works on`}</h2>
    <div>
  Please check <Link className="gatsby-link" to="/not_ready" mdxType="Link">Projects</Link> for my portfolios, these sections are 'packages' that I interested to work on, click the cards to know more.
    </div>
    <div style={{
      "display": "flex",
      "flexWrap": "wrap"
    }}>
  <GenericCard title="Greenfield Project" img={GreenfieldJPG} link="/not_ready" linkName="Learn More" mdxType="GenericCard">
    Greenfield project is a project that doesn't have any technical implementation yet. I am highly interested in this kind of project because bringing ideas to life has its own exciting challenges.
  </GenericCard>
  <GenericCard title="Static Site" img={StaticSiteJPG} imgMargin="-105px" link="/not_ready" linkName="Learn More" mdxType="GenericCard">
    Just started a business? Want a simple single landing /marketing page to introduce yourself to the market? Provide the design and I could work on it right away!
  </GenericCard>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      